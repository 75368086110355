import Line from 'components/charts/Line';
import Pie from 'components/charts/Pie';
import Stream from 'components/charts/Stream';
import { lineData } from 'data/lineData';
import { pieData } from 'data/pieData';
import { streamData } from 'data/streamData';

export default function AirconDashboard() {
  return (
    <>
      <div className="lg:w-[calc(100%_-_6rem)] m-12 h-128 w-11/12 bg-white-100">
        <Line data={lineData} />
      </div>
    </>
  );
}
