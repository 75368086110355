import Home from 'containers/Home';
// import Contact from 'containers/Contact';
import Footer from 'components/layout/Footer';
import Header from 'components/layout/Header';
import { Routes, Route } from 'react-router-dom';
import AboutUs from 'containers/AboutUs';
import NotFound from 'containers/NotFound';

function Template() {
  return (
    <>
      <Header />
      <main className="min-h-full min-w-full">
        <article className="pt-32">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about-us" element={<AboutUs />} />
            {/* <Route path="/contact" element={<Contact />} /> */}
            <Route path="/*" element={<NotFound />} />
          </Routes>
        </article>
      </main>
      <Footer />
    </>
  );
}

export default Template;
