// import { applyMiddleware, compose, Middleware, Store } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import {
  // persistCombineReducers,
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import { rootReducer } from 'reducers';

// import reducers from 'reducers';
// import rootSaga from 'sagas';
// import middleware, { sagaMiddleware } from './middleware';

// const rootReducer = persistCombineReducers<RootState>(
//   {
//     key: 'rrsb',
//     stateReconciler: autoMergeLevel2,
//     storage,
//     blacklist: ['alerts'],
//     timeout: 0,
//   },
//   reducers,
// );

// const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// /* istanbul ignore next */
// export const configStore = (initialState: any = {}, additionalMiddleware: Middleware[] = []) => {
//   const store: Store = createStore(
//     rootReducer,
//     initialState,
//     composeEnhancer(applyMiddleware(...additionalMiddleware, ...middleware)),
//   );

//   sagaMiddleware.run(rootSaga);

//   if (module.hot) {
//     module.hot.accept('reducers', () => {
//       store.replaceReducer(rootReducer);
//     });
//   }

//   return {
//     persistor: persistStore(store),
//     store,
//   };
// };

const rootPersistConfig = {
  key: 'root',
  storage,
  version: 1,
  whitelist: ['contactReducer'],
};

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

const initStore = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  devTools: process.env.NODE_ENV !== 'production',
});

export type AppDispatch = typeof initStore.dispatch;
export type RootState = ReturnType<typeof initStore.getState>;

export default () => {
  return { store: initStore, persistor: persistStore(initStore) };
};
