import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from 'containers/admin/Login';
import Template from 'components/layout/Template';
import AdminTemplate from 'components/layout/admin/AdminTemplate';

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/admin" element={<Login />} />
        <Route path="/admin/:menuType/dashboard" element={<AdminTemplate />} />
        <Route path="/*" element={<Template />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
