import { API_KEY, CLIENT_ID } from 'constants/googleOAuth';
import useScript from 'hooks/useScript';
import { useEffect, useRef } from 'react';
import { IGoogleLoinProps } from 'types/props';
import jwt_decode from 'jwt-decode';

function handleCredentialResponse(response: any) {
  const test = jwt_decode(response.credential);
  console.log(test);
}

export default function GoogleLogin({ onGoogleSignIn, text = '로그인' }: IGoogleLoinProps) {
  const googleSignInButton = useRef(null);
  const status = useScript('https://accounts.google.com/gsi/client');

  useEffect(() => {
    console.log(status);
    if (status === 'ready') {
      (window as any).google.accounts.id.initialize({
        client_id: CLIENT_ID,
        callback: handleCredentialResponse,
      });

      // (window as any).google.accounts.id.prompt();

      (window as any).google.accounts.id.renderButton(document.getElementById('buttonDiv'), {
        width: '250',
        shape: 'pill',
      });
    }
  }, [status]);

  return (
    <>
      {/* <div> status: {status}</div> */}
      <div id="google-login-api" ref={googleSignInButton} />
    </>
  );
}
