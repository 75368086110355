import React from 'react';
import ReactDOM from 'react-dom/client';
import 'index.css';
import 'pretendard-subset.css';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import i18n from 'lang/i18n';
import Router from 'Router';
import { I18nextProvider } from 'react-i18next';
import configStore from 'store';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const langList = ['ko', 'ko-KR', 'en', 'en-US'];
const lang = langList.includes(window.navigator.language) ? window.navigator.language : 'en';

const { store, persistor } = configStore();
const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <I18nextProvider i18n={i18n(lang)}>
            <Router />
          </I18nextProvider>
        </PersistGate>
      </Provider>
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    </QueryClientProvider>
  </React.StrictMode>,
);
