// {
//   ['todos', 'list', { filters: 'all' }],
//   ['todos', 'list', { filters: 'done' }],
//   ['todos', 'detail', 1],
//   ['todos', 'detail', 2],
// }

// const todoKeys = {
//   all: ['todos'] as const,
//   lists: () => [...todoKeys.all, 'list'] as const,
//   list: (filters: string) => [...todoKeys.lists(), { filters }] as const,
//   details: () => [...todoKeys.all, 'detail'] as const,
//   detail: (id: number) => [...todoKeys.details(), id] as const,
// };

import { BusinessTypes } from 'types/admin/route';

const partnerTypes = 'S' || 'A' || 'J';

export type MonthlySalesKeyType =
  | readonly ['monthly_sales']
  | readonly ['monthly_sales', BusinessTypes]
  | readonly ['monthly_sales', BusinessTypes, typeof partnerTypes];

export const MonthlySalesKeys = {
  default: ['monthly_sales'] as const,
  businessType: (bType: BusinessTypes) => [...MonthlySalesKeys.default, bType] as const,
  partnerType: (bType: BusinessTypes, ptype: typeof partnerTypes) =>
    [...MonthlySalesKeys.businessType(bType), ptype] as const,
};
