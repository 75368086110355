import { createSlice } from '@reduxjs/toolkit';
import { ContactInputPayload, ContactState } from 'types';
import type { PayloadAction } from '@reduxjs/toolkit';

export const initialState: ContactState = {
  // completedContact: null,
  contactInputs: {
    name: '',
    phoneNumber: null,
    email: '',
    type: '',
    content: '',
  },
};

export const contactSlice = createSlice({
  name: 'contact',
  initialState,
  reducers: {
    changeContactInput: (state, action: PayloadAction<ContactInputPayload>) => {
      const [key, value] = action.payload;
      state.contactInputs[key] = value;
    },
  },
});

export const { changeContactInput } = contactSlice.actions;
export default contactSlice.reducer;
