import { BarPops } from 'types/props';
import { ResponsiveBar } from '@nivo/bar';

export default function Bar({ content }: BarPops) {
  return (
    <ResponsiveBar
      data={content.keyValues}
      keys={['매출', '비용']}
      indexBy="key"
      margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
      padding={0.3}
      groupMode="stacked"
      valueFormat=" >-,"
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      colors={{ scheme: 'accent' }}
      borderColor={{
        from: 'color',
        modifiers: [['darker', 1.6]],
      }}
      // axisTop={{
      //   legend: `백만${content.valueUnit}`,
      //   // legend: content.valueUnit,
      //   legendPosition: 'start',
      //   // legendOffset: -46,
      // }}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: content.keyUnit,
        legendPosition: 'middle',
        legendOffset: 32,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: -20,
        legend: `백만${content.valueUnit}`,
        // legend: content.valueUnit,
        legendPosition: 'end',
        legendOffset: -46,
        format: (v) => {
          return v * 0.000001;
        },
      }}
      labelSkipWidth={13}
      labelSkipHeight={12}
      labelTextColor="black"
      legends={[
        {
          dataFrom: 'keys',
          anchor: 'bottom-right',
          direction: 'column',
          justify: false,
          translateX: 115,
          translateY: 0,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: 'left-to-right',
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: 'hover',
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      // tooltip={({ id, value }) => {
      //   return (
      //     <div
      //       style={{
      //         background: 'white',
      //         padding: '9px 12px',
      //         border: '1px solid #ccc',
      //       }}
      //     >
      //       <div>
      //         {id}: {value}
      //       </div>
      //     </div>
      //   );
      // }}
      role="application"
      ariaLabel="Nivo bar chart demo"
      barAriaLabel={(e) => e.id + ': ' + e.formattedValue + ' in country: ' + e.indexValue}
    />
  );
}
