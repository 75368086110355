import Header from 'components/layout/admin/Header';
import { HOME_IMG_SRC } from 'constants/common';
import GoogleLogin from './GoogleLogin';

export default function Login() {
  return (
    <section className="container flex h-screen w-full flex-col-reverse items-center justify-evenly md:flex-row md:justify-around">
      {/* <Header /> */}
      <div className="flex min-h-full min-w-full flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img className="mx-auto h-12 w-auto" src={HOME_IMG_SRC.KOR_LOGO} alt="Your Company" />
          <h2 className="mt-8 text-center text-lg font-medium leading-9 tracking-tight text-gray-800 md:text-2xl">
            이올 계정으로 로그인 해주세요.
          </h2>
          <p className="text-center text-xs  font-light leading-6 tracking-tight text-gray-500 md:text-base md:leading-8">
            (이올 계정이 없을 경우 관리자에게 문의 부탁드립니다.)
          </p>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6">
            {/* <div>
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                Email address
              </label>
              <div className="mt-2">
                <input
                  // id="email"
                  name="email"
                  type="email"
                  // autoComplete="email"
                  required
                  className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm outline-none ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 autofill:bg-blue-300 focus:ring-1 focus:ring-inset focus:ring-green-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div> */}

            <div>
              {/* <div className="flex items-center justify-between">
                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                  Password
                </label>
              </div>
              <div className="mt-2">
                <input
                  // id="password"
                  name="password"
                  type="password"
                  // autoComplete="current-password"
                  required
                  className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm outline-none ring-1 ring-inset  ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-green-500 sm:text-sm sm:leading-6"
                />
              </div> */}
            </div>

            {/* <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-green p-2.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                로그인
              </button>
            </div> */}
            <GoogleLogin />
            <div className="flex w-full justify-center" id="buttonDiv" />
          </form>
        </div>
      </div>
    </section>
  );
}
