import { KeyString } from 'types';
import { ObjectReplacementInfo } from 'types/admin/chart';

const keyNames = [
  { originKey: 'sales', newKey: '매출' },
  { originKey: 'cost', newKey: '비용' },
];

export const TEMP_KEYS = keyNames.map((elem) => elem.newKey);

export default function replaceObjectKeys<OriginObj extends KeyString>(
  originObjects: OriginObj[],
  keyList: ObjectReplacementInfo[] = keyNames,
  isDelete = true,
): OriginObj[] | false {
  if (originObjects?.length === 0 || !originObjects) return false;

  const copiedOriginOptions = deepCopy(originObjects);

  return copiedOriginOptions.map((elem) => {
    keyList.forEach(({ originKey, newKey }) => {
      renameObjectKey(elem, originKey, newKey, isDelete);
    });
    return elem;
  });
}

function deepCopy<OriginObj>(originObjects: OriginObj[]) {
  return originObjects.map((elem: OriginObj) => ({ ...elem }));
}

function renameObjectKey<Obj extends KeyString>(
  originObject: Obj,
  originKey: string,
  newKey: string,
  isDelete: boolean,
) {
  if (!originObject.hasOwnProperty(originKey)) return;

  // originObject[newKey] = originObject[originKey];
  (originObject as KeyString)[newKey] = originObject[originKey];
  isDelete && delete originObject[originKey];
}
