export const gjgsCommercial = 'gjgs-commercial';
export const commercial = 'commercial';
export const gjgsSystemAircon = 'gjgs-system-aircon';
export const systemAircon = 'systemAircon';
export const kitcheniol = 'kitcheniol';

export type AdminRouteTypes = typeof gjgsCommercial | typeof gjgsSystemAircon | typeof kitcheniol;
export type BusinessTypes = typeof commercial | typeof systemAircon | typeof kitcheniol;

export type CommercialType = [typeof gjgsCommercial, typeof commercial];
export type AirconType = [typeof gjgsSystemAircon, typeof systemAircon];
export type KitcheniolType = [typeof kitcheniol, typeof kitcheniol];

export type RouteBusinessTypes = CommercialType | AirconType | KitcheniolType;
