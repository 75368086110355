import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { HOME_IMG_SRC, LANGUAGE_FOR_OS, LANG_ICON, ROUTES } from 'constants/common';
import { useRef } from 'react';
import { isMobileOnly } from 'react-device-detect';

function Header() {
  const { pathname } = useLocation();
  const { i18n } = useTranslation();
  const languageMenu = useRef<HTMLUListElement>(null);
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const [isShowLanguageMenu, setIsShowLanguageMenu] = useState(false);
  const [logoSrc, setLogoSrc] = useState(() => {
    if (i18n.language.includes(LANGUAGE_FOR_OS.KO)) {
      return HOME_IMG_SRC.KOR_LOGO;
    }
    if (i18n.language.includes(LANGUAGE_FOR_OS.EN)) {
      return HOME_IMG_SRC.ENG_LOGO;
    }
  });

  // useEffect(() => {
  //   console.log(isMobileNavOpen);
  // }, []);

  function handleCloseLanguage(this: Window, ev: MouseEvent) {
    const target = ev.target as HTMLElement;
    if (target.id !== LANG_ICON && isShowLanguageMenu && !languageMenu?.current?.contains(target)) {
      setIsShowLanguageMenu(false);
    }
  }
  useEffect(() => {
    window.addEventListener('click', handleCloseLanguage);
    return () => {
      window.removeEventListener('click', handleCloseLanguage);
    };
  }, [isShowLanguageMenu]);

  const showMobileNav = () => {
    if (isMobileOnly) {
      console.log(isMobileNavOpen);

      setIsMobileNavOpen((prev) => !prev);

      if (!isMobileNavOpen) {
        document.body.style.cssText = `
        overflow: hidden; 
        `;
        return;
      }
      document.body.style.cssText = `
      overflow: visible; 
      `;
    }
  };
  const hideMobileNav = () => {
    setIsMobileNavOpen(false);
    document.body.style.cssText = `
    overflow: visible;
    `;
  };

  const changeLanguage = (newLanguage: string): void => {
    if (newLanguage.includes(LANGUAGE_FOR_OS.KO)) {
      i18n.changeLanguage(LANGUAGE_FOR_OS.KO);
      setLogoSrc(HOME_IMG_SRC.KOR_LOGO);
      return;
    }
    if (newLanguage.includes(LANGUAGE_FOR_OS.EN)) {
      i18n.changeLanguage(LANGUAGE_FOR_OS.EN);
      setLogoSrc(HOME_IMG_SRC.ENG_LOGO);

      return;
    }
  };

  const LanguageMenuBarHandler = (newLanguage: string) => {
    isShowLanguageMenu && setIsShowLanguageMenu(false);
    isMobileNavOpen && showMobileNav();
    changeLanguage(newLanguage);
  };

  return (
    <>
      <header
        className={`${isMobileNavOpen ? 'bg-headerBg100' : 'bg-headerBg70'}
        fixed inset-x-0 top-0 z-20 flex h-32 items-center justify-between px-5 backdrop-blur-sm md:px-12  xl:px-80`}
      >
        <section onClick={hideMobileNav}>
          <Link to="/">
            <figure className="w-28 xl:w-32">
              <img src={logoSrc} alt="이올로고" />
            </figure>
          </Link>
        </section>

        <section className="hidden md:block">
          <div className="relative flex w-[20rem] items-center justify-evenly text-xl font-semibold text-black">
            {/* <div className="relative flex w-[20rem] items-center justify-between text-xl font-semibold text-black"> 헤더에 Contact 메뉴 있을 때 */}
            {/* <nav className="flex justify-around"> */}
            <Link
              to="/about-us"
              className={`inline-block ${pathname.includes(ROUTES.ABOUT) && 'text-green'}`}
            >
              About Us
            </Link>
            {/* <Link
              to="/contact"
              className={`inline-block ${pathname.includes(ROUTES.CONTACT) && 'text-green'}`}
            >
              Contact
            </Link> */}
            {/* </nav> */}
            <button className="inline-block" onClick={() => setIsShowLanguageMenu(!isShowLanguageMenu)}>
              <img src="/images/lang_icon.svg" alt="언어설정" className="w-5" id={LANG_ICON} />
            </button>

            {isShowLanguageMenu && (
              <ul className="lang_ul opacity-100" ref={languageMenu}>
                <li className={`lang_li ${i18n.language.includes(LANGUAGE_FOR_OS.KO) && 'lang_li-clicked'}`}>
                  <button onClick={() => LanguageMenuBarHandler(LANGUAGE_FOR_OS.KO)}>한국어</button>
                </li>
                <li className={`lang_li ${i18n.language.includes(LANGUAGE_FOR_OS.EN) && 'lang_li-clicked'}`}>
                  <button onClick={() => LanguageMenuBarHandler(LANGUAGE_FOR_OS.EN)}>English</button>
                </li>
              </ul>
            )}
          </div>
        </section>

        <section className="md:hidden" onClick={showMobileNav}>
          {!isMobileNavOpen ? (
            <div className="space-y-2">
              <span className="block h-0.5 w-8 bg-gray-500"></span>
              <span className="block h-0.5 w-8 bg-gray-500"></span>
              <span className="block h-0.5 w-8 bg-gray-500"></span>
            </div>
          ) : (
            <svg
              className="h-8 w-8 text-gray-500"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <line x1="18" y1="6" x2="6" y2="18" />
              <line x1="6" y1="6" x2="18" y2="18" />
            </svg>
          )}
        </section>
      </header>

      {isMobileNavOpen && (
        <>
          <div className="fixed z-[2] flex h-full w-screen bg-gray-500 opacity-50"></div>
          <div className="fixed z-50 mt-32 h-1/4 w-full rounded-b-2xl bg-headerBg100 px-5">
            <nav className="flex h-1/2 flex-col justify-between text-xl font-medium" onClick={showMobileNav}>
              <Link
                to="/about-us"
                className={`inline-block ${pathname.includes(ROUTES.ABOUT) && 'text-green'}`}
              >
                About Us
              </Link>

              {/* <Link to="/contact" className="inline-block ml-4">
                Contact
              </Link> */}
            </nav>
            <ul className="mt-10 flex w-2/3 items-center justify-around text-gray-500">
              <li>
                <img src="/images/lang_icon-gray.svg" alt="언어설정" className="w-5 " />
              </li>
              <li className={`lang_li ${i18n.language.includes(LANGUAGE_FOR_OS.KO) && 'lang_li-clicked'}`}>
                <button onClick={() => LanguageMenuBarHandler(LANGUAGE_FOR_OS.KO)}>한국어</button>
              </li>
              <li> | </li>
              <li className={`lang_li ${i18n.language.includes(LANGUAGE_FOR_OS.EN) && 'lang_li-clicked'}`}>
                <button onClick={() => LanguageMenuBarHandler(LANGUAGE_FOR_OS.EN)}>English</button>
              </li>
            </ul>
          </div>
        </>
      )}
    </>
  );
}

export default Header;
