import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

function Footer() {
  const { t } = useTranslation();

  return (
    <footer className="absolute left-0 h-80 w-full bg-gray text-gray-400">
      <hr className="border-t-2 border-t-gray-200" />
      <div className="flex h-full justify-center">
        <section className="flex h-full w-11/12 flex-col items-center justify-evenly md:w-3/4 md:flex-row lg:w-3/5 lg:min-w-[800px]">
          <div>
            <img src="/images/img_iol-symbol.svg" alt="iol" />
          </div>
          <div className="text-center md:text-left">
            <span className="block ">{t('company')}</span>
            <span className="block">{t('address')}</span>
            <span className="md:mr-6">{t('businessNum')}</span>
            {isMobile && <br />}
            <span>{t('manager')}</span>
            <br />
            <span className="md:mr-6">{t('tel')}</span>
            {isMobile && <br />}
            <span>{t('companyEmail')}</span>
          </div>
        </section>
      </div>
    </footer>
  );
}
export default Footer;
