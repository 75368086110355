import { AxiosError } from 'axios';
import { QueryFunction, QueryKey, useQuery } from '@tanstack/react-query';
import { MonthlySalesKeyType } from 'constants/queryKeys';
import formatReactSelectOptions from 'utils/formatChartData';

export interface Data {
  data: {
    keyValues: [];
    keyUnit: string;
    valueUnit: string;
  };
}
export interface ChartData {
  data: Data;
  succeeded?: boolean;
  message?: string | null;
}

export function useMonthlySalesQuery(monthlySalesKey: MonthlySalesKeyType, fetchMonthlySales: any): any {
  const {
    data: response,
    isSuccess,
    isError,
    error,
  } = useQuery<any>(monthlySalesKey, fetchMonthlySales, {
    select(data) {
      console.log(data);
      // 여기서 차트라이브러리에 맞게 데이터 가공해서 리턴시키기
      return { ...data.data, keyValues: formatReactSelectOptions(data.data.keyValues) };
    },
  });
  // console.log(response);
  // if (isSuccess) {
  //   if (!response?.succeeded) {
  //     return { response };
  //   }

  //   if (response?.succeeded) {
  //     return { response };
  //   }
  // }

  // if (isError) {
  //   console.log(error);
  //   return error;
  // }

  return { response };
}
