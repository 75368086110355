import { HOME_IMG_SRC, LANGUAGE_FOR_OS } from 'constants/common';
import { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next'; // 영어 변환 라이브러리 사용 (brandTitle)
import { isMobileOnly } from 'react-device-detect';

function Home() {
  const { t, i18n } = useTranslation();
  const [chartSrc, setChartSrc] = useState(() => {
    if (i18n.language.includes(LANGUAGE_FOR_OS.KO)) {
      return HOME_IMG_SRC.KOR_CHART;
    }
    if (i18n.language.includes(LANGUAGE_FOR_OS.EN)) {
      return HOME_IMG_SRC.ENG_CHART;
    }
  });

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  useEffect(() => {
    const { language } = i18n;
    if (language.includes(LANGUAGE_FOR_OS.EN)) {
      setChartSrc(HOME_IMG_SRC.ENG_CHART);
    }
    if (language.includes(LANGUAGE_FOR_OS.KO)) {
      setChartSrc(HOME_IMG_SRC.KOR_CHART);
    }
  }, [i18n.language]);

  return (
    <>
      <section className="container flex h-[calc(100vh-128px)] w-full flex-col-reverse items-center justify-evenly  md:flex-row md:justify-around">
        <div>
          <h1 className="text-6xl font-bold md:text-7xl lg:text-8xl">
            improve <br /> our <br />
            lives
          </h1>
          <p className="text-base font-medium lg:text-xl">
            {t('homeTitle1')} <br /> {t('homeTitle2')}
          </p>
        </div>
        <img src="/images/home/img_main.svg" alt="main" className="md:w-1/2 lg:w-2/5 w-11/12" />
      </section>
      <section className="md:h-[calc(100vh-128px)] mt-32 flex h-full flex-col  justify-center md:my-2 lg:my-0">
        <div className="left-0 w-screen bg-gray-50">
          <div className="bg-gray-100">
            {/* <div className="container mx-auto"> */}
            <p className="container mx-auto bg-gray-100 pb-10 text-center text-xl font-bold md:text-2xl">
              {t('mission1')}
              {!isMobileOnly && <br />}
              {t('mission2')}
            </p>
            {/* </div> */}
          </div>
          <div className="container mx-auto flex flex-col items-center justify-around md:h-96 md:flex-row">
            {/* 미션 컴포넌트 */}
            <div className="m-2 flex flex-col items-center md:m-1 md:w-56 lg:w-64">
              {/* <object type="image/svg+xml" data="/images/home/img_learning.svg" className="w-56"> */}
              <img src="/images/home/img_learning.png" className="max-w-[180px]" alt="learning" />
              {/* </object> */}
              <h3 className="mb-6 mt-4 self-center text-xl font-bold">LEARNING</h3>
              <p className="pb-10 text-center font-medium md:text-sm lg:text-base">
                {t('learning1')}
                <br />
                {t('learning2')}
              </p>
            </div>

            {/* 미션 컴포넌트 */}
            <div className="m-2 flex flex-col items-center md:m-1 md:w-56 lg:w-64">
              {/* <object type="image/svg+xml" data="/images/home/img_synergy.svg" className="w-56"> */}
              <img src="/images/home/img_synergy.png" className="max-w-[180px]" alt="synergy" />
              {/* </object> */}
              <h3 className="mb-6 mt-4 self-center text-xl font-bold ">SYNERGY</h3>
              <p className="pb-10 text-center font-medium md:text-sm lg:text-base">
                {t('synergy1')}
                <br />
                {t('synergy2')}
              </p>
            </div>

            {/* 미션 컴포넌트 */}
            <div className="m-2 flex flex-col items-center md:m-1 md:w-56 lg:w-64">
              {/* <object type="image/svg+xml" data="/images/home/img_challenge.svg" className="w-56"> */}
              <img src="/images/home/img_challenge.png" className="max-w-[180px]" alt="challenge" />
              {/* </object> */}
              <h3 className="mb-6 mt-4 self-center text-xl font-bold ">CHALLENGE</h3>
              <p className="pb-10 text-center font-medium md:text-sm lg:text-base">
                {t('challenge1')}
                <br />
                {t('challenge2')}
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="flex h-[calc(100vh-128px)] flex-col justify-center md:my-0">
        <div className="container mx-auto flex h-96 flex-col items-center justify-center lg:flex-row">
          <img src={chartSrc} alt="chart" className="md:w-2/3 lg:w-1/3 xl:w-2/5 w-full" />
          <div className="mt-12 lg:ml-32">
            <h1 className="text-xl font-bold text-secondary ">Business Result</h1>
            <p className="my-4 text-4xl font-bold xl:text-6xl">{t('businessResult1')}</p>
            <p className="my-4 text-4xl font-bold xl:text-6xl">{t('businessResult2')}</p>
          </div>
        </div>
      </section>

      {/* 연혁 */}
      <section className="flex w-full flex-col items-center justify-center bg-gray-50 pt-32">
        <h1 className="mb-12 text-2xl font-bold md:mb-24">{t('historyTitle')}</h1>
        <div className="container mx-auto mb-12 flex h-full flex-col items-center justify-center md:mb-32 md:flex-row">
          <div className="history_container">
            <div>
              <h1 className="history_year">2022</h1>
              <h2 className="history_date ">2022.07</h2>
              <ol className="history_ol">
                <li className="history_multiple_li">{t('202207Content1')}</li>
                <li className="history_multiple_li">{t('202207Content2')}</li>
              </ol>
              <h2 className="history_date">2022.06</h2>
              <ol className="history_ol">
                <li className="history_li">{t('202206Content1')}</li>
              </ol>
              <h2 className="history_date">2022.05</h2>
              <ol className="history_ol">
                <li className="history_li">{t('202205Content1')}</li>
              </ol>
            </div>
            <div>
              <h1 className="history_year">2021</h1>
              <h2 className="history_date">2021.12</h2>
              <ol className="history_ol">
                <li className="history_li">{t('202112Content1')}</li>
              </ol>
              <h2 className="history_date">2021.09</h2>
              <ol className="history_ol">
                <li className="history_li">{t('202109Content1')}</li>
              </ol>
              <h2 className="history_date">2021.03</h2>
              <ol className="history_ol">
                <li className="history_li">{t('202103Content1')}</li>
              </ol>
              <h2 className="history_date">2021.01</h2>
              <ol className="history_ol">
                <li className="history_li">{t('202101Content1')}</li>
              </ol>
            </div>
            <div>
              <h1 className="history_year">2020</h1>
              <h2 className="history_date">2020.12</h2>
              <ol className="history_ol">
                <li className="history_li">{t('202012Content1')}</li>
              </ol>
              <h2 className="history_date">2020.11</h2>
              <ol className="history_ol">
                <li className="history_li">{t('202011Content1')}</li>
              </ol>
              <h2 className="history_date">2020.05</h2>
              <ol className="history_ol">
                <li className="history_li">{t('202005Content1')}</li>
              </ol>
            </div>
            <div>
              <h1 className="history_year">2019</h1>
              <h2 className="history_date">2019.12</h2>
              <ol className="history_ol">
                <li className="history_li">{t('201912Content1')}</li>
              </ol>
              <h2 className="history_date">2019.11</h2>
              <ol className="history_ol">
                <li className="history_li">{t('201911Content1')}</li>
              </ol>
              <h2 className="history_date">2019.05</h2>
              <ol className="history_ol">
                <li className="history_li">{t('201905Content1')}</li>
              </ol>
              <h2 className="history_date">2019.03</h2>
              <ol className="history_ol">
                <li className="history_li">{t('201903Content1')}</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="flex w-full flex-col items-center justify-center bg-gray pt-32">
        <h1 className="mb-12 text-2xl font-bold ">{t('brandTitle')}</h1>
        <div className="container mx-auto mb-12 flex h-full flex-col items-center justify-center md:mb-0 md:flex-row">
          <div className="md:h-[300px]">
            <div className="m-4 flex h-24 w-64 items-center justify-center rounded-2xl bg-gray-200 px-10 py-4 md:h-32 md:w-80 lg:w-96 ">
              <a href='https://www.youtube.com/c/gjgs_official' target='_blank' rel="noreferrer"><img alt="gjgs" src="/images/home/gjgs_logo.svg" /></a>
            </div>
          </div>
          <div className="md:h-[300px] flex flex-col items-center">
            <div className="m-4 flex h-24 w-64 items-center justify-center rounded-2xl bg-gray-200 px-10 py-4 md:h-32 md:w-80 lg:w-96">
            <a href='https://kitcheniol.com/' target='_blank' rel="noreferrer"><img alt="kitcheniol" src="/images/home/kitcheniol_logo_black.svg" /></a>
            </div>
            {/* <div className="px-4 py-1 bg-gray-300 rounded-2xl">
              <span className="text-sm font-bold text-white">{t('openDate')}</span>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
}

export default Home;

// .history h2 {position: relative; font-size: 33px color: #000; line-height: 1;}
// .history h2:before {
//   content:""; position: absolute;
//   left:-48px; top:50%; transform: translateY(-50%);
//   width: 17px; height: 17px; border-radius: 100%;
//   background:#fff; border: 5px solid #ff5a2b;
//   box-sizing: border-box;}

// .history div {position: relative; padding: 0 0 0 48px;}
// .history div:before { content:""; position:absolute; left:8px; top:0;
//         width: 2px; height:100%; background:#ddd;}
// .history div:first-child:before { top:10px; height:calc(100% - 10px);}

// .history ol { padding: 20px 0 74px; }
// .history li {font-size: 17px; color:#000; line-height: 29px;  }
