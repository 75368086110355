export const pieData = [
  {
    id: 'c',
    label: 'c',
    value: 520,
    color: 'hsl(347, 70%, 50%)',
  },
  {
    id: 'make',
    label: 'make',
    value: 78,
    color: 'hsl(37, 70%, 50%)',
  },
  {
    id: 'python',
    label: 'python',
    value: 15,
    color: 'hsl(124, 70%, 50%)',
  },
  {
    id: 'rust',
    label: 'rust',
    value: 531,
    color: 'hsl(17, 70%, 50%)',
  },
  {
    id: 'java',
    label: 'java',
    value: 280,
    color: 'hsl(262, 70%, 50%)',
  },
];
