import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import localeKOR from 'lang/lang_kor.json';
import localeENG from 'lang/lang_eng.json';
import { LANGUAGE_FOR_OS } from 'constants/common';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  [LANGUAGE_FOR_OS.EN]: {
    translation: localeENG,
  },
  [LANGUAGE_FOR_OS.EN_US]: {
    translation: localeENG,
  },
  [LANGUAGE_FOR_OS.KO]: {
    translation: localeKOR,
  },
  [LANGUAGE_FOR_OS.KO_KR]: {
    translation: localeKOR,
  },
};

const init = (lang: string) => {
  i18n.use(initReactI18next).init({
    resources,
    fallbackLng: 'en',
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false,
    },
    lng: lang,
  });
  return i18n;
};

// i18n
//   .use(initReactI18next) // passes i18n down to react-i18next
//   .init({
//     resources,
//     lng: 'kor', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
//     // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
//     // if you're using a language detector, do not define the lng option
//     fallbackLng: 'en',
//     debug: process.env.NODE_ENV === 'development',
//     interpolation: {
//       escapeValue: false, // react already safes from xss
//     },
//   });

export default init;
