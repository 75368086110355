export const streamData = [
  {
    Raoul: 125,
    Josiane: 171,
    Marcel: 186,
    René: 151,
    Paul: 71,
    Jacques: 95,
  },
  {
    Raoul: 136,
    Josiane: 24,
    Marcel: 188,
    René: 31,
    Paul: 122,
    Jacques: 176,
  },
  {
    Raoul: 107,
    Josiane: 81,
    Marcel: 15,
    René: 51,
    Paul: 112,
    Jacques: 198,
  },
  {
    Raoul: 16,
    Josiane: 24,
    Marcel: 198,
    René: 192,
    Paul: 89,
    Jacques: 100,
  },
  {
    Raoul: 104,
    Josiane: 31,
    Marcel: 164,
    René: 18,
    Paul: 161,
    Jacques: 85,
  },
  {
    Raoul: 141,
    Josiane: 117,
    Marcel: 83,
    René: 68,
    Paul: 92,
    Jacques: 18,
  },
  {
    Raoul: 70,
    Josiane: 127,
    Marcel: 121,
    René: 60,
    Paul: 49,
    Jacques: 97,
  },
  {
    Raoul: 165,
    Josiane: 104,
    Marcel: 53,
    René: 169,
    Paul: 128,
    Jacques: 126,
  },
  {
    Raoul: 52,
    Josiane: 106,
    Marcel: 104,
    René: 143,
    Paul: 99,
    Jacques: 178,
  },
];
