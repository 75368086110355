import Bar from 'components/charts/Bar';
import { lineData } from 'data/lineData';
import Line from 'components/charts/Line';
import { pieData } from 'data/pieData';
import Pie from 'components/charts/Pie';
import { streamData } from 'data/streamData';
import Stream from 'components/charts/Stream';
import { MonthlySalesKeys } from 'constants/queryKeys';
import { RouteBusinessTypes } from 'types/admin/route';
import { useMonthlySalesQuery } from 'hooks/query/useMonthlySalesQuery';
import { getMonthlySales } from 'apis/admin';

export default function CommercialDashboard({ type }: { type: RouteBusinessTypes }) {
  const { response } = useMonthlySalesQuery(MonthlySalesKeys.businessType(type[1]), getMonthlySales);

  // const changeChartContent = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   setXContent(event.currentTarget.name);
  // };

  // const queryClient = useQueryClient({});
  // console.log(queryClient.getQueryData(MonthlySalesKeys.businessType(type[1])).data.keyValues);

  return (
    <>
      <div className="chart_container-w mx-auto my-4 h-128 bg-white-100">
        {response && <Bar content={response} />}
        <div className="mt-6 flex items-center justify-center">
          <button
            // type="button"
            name="sales"
            className="mr-4 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            // onClick={changeChartContent}
          >
            파트너A
          </button>
          <button
            type="button"
            name="cost"
            className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            // onClick={changeChartContent}
          >
            파트너B
          </button>
        </div>
      </div>
      <div className="chart_container-w mb-4 mt-16 h-128 bg-white-100">
        <Line data={lineData} />
      </div>
      <div className="chart_container-w flex flex-col items-center lg:flex-row">
        <div className="mb-4 h-96 w-full bg-white-100 lg:mr-4 lg:h-128 lg:w-1/2">
          <Stream data={streamData} />
        </div>
        <div className="mb-4 h-96 w-full bg-white-100 lg:h-128 lg:w-1/2 ">
          <Pie data={pieData} />
        </div>
      </div>
    </>
  );
}
