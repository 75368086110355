import { Get, client } from 'apis';

export const getMonthlySales = async <T>(): Promise<T> => {
  const response = await Get<T>(`/api/statistics/sales/monthly`);
  // const response = await Get(`/api/shop/apartmentary/info`);

  return response;
};

// export const getMonthlySales = async () => {
//   const response = await client.get(`/api/statistics/sales/monthly`);
//   // const response = await Get(`/api/shop/apartmentary/info`);

//   return response;
// };
