export const lineData = [
  {
    id: 'japan',
    color: 'hsl(332, 70%, 50%)',
    data: [
      {
        x: 'plane',
        y: 19,
      },
      {
        x: 'helicopter',
        y: 24,
      },
      {
        x: 'boat',
        y: 188,
      },
      {
        x: 'train',
        y: 107,
      },
      {
        x: 'subway',
        y: 172,
      },
      {
        x: 'bus',
        y: 117,
      },
      {
        x: 'car',
        y: 124,
      },
      {
        x: 'moto',
        y: 25,
      },
      {
        x: 'bicycle',
        y: 137,
      },
      {
        x: 'horse',
        y: 136,
      },
      {
        x: 'skateboard',
        y: 91,
      },
      {
        x: 'others',
        y: 224,
      },
    ],
  },
  {
    id: 'france',
    color: 'hsl(6, 70%, 50%)',
    data: [
      {
        x: 'plane',
        y: 244,
      },
      {
        x: 'helicopter',
        y: 279,
      },
      {
        x: 'boat',
        y: 169,
      },
      {
        x: 'train',
        y: 282,
      },
      {
        x: 'subway',
        y: 271,
      },
      {
        x: 'bus',
        y: 294,
      },
      {
        x: 'car',
        y: 137,
      },
      {
        x: 'moto',
        y: 89,
      },
      {
        x: 'bicycle',
        y: 12,
      },
      {
        x: 'horse',
        y: 274,
      },
      {
        x: 'skateboard',
        y: 226,
      },
      {
        x: 'others',
        y: 238,
      },
    ],
  },
  {
    id: 'us',
    color: 'hsl(10, 70%, 50%)',
    data: [
      {
        x: 'plane',
        y: 228,
      },
      {
        x: 'helicopter',
        y: 158,
      },
      {
        x: 'boat',
        y: 222,
      },
      {
        x: 'train',
        y: 189,
      },
      {
        x: 'subway',
        y: 90,
      },
      {
        x: 'bus',
        y: 94,
      },
      {
        x: 'car',
        y: 245,
      },
      {
        x: 'moto',
        y: 202,
      },
      {
        x: 'bicycle',
        y: 253,
      },
      {
        x: 'horse',
        y: 156,
      },
      {
        x: 'skateboard',
        y: 269,
      },
      {
        x: 'others',
        y: 142,
      },
    ],
  },
  {
    id: 'germany',
    color: 'hsl(122, 70%, 50%)',
    data: [
      {
        x: 'plane',
        y: 208,
      },
      {
        x: 'helicopter',
        y: 159,
      },
      {
        x: 'boat',
        y: 277,
      },
      {
        x: 'train',
        y: 19,
      },
      {
        x: 'subway',
        y: 227,
      },
      {
        x: 'bus',
        y: 268,
      },
      {
        x: 'car',
        y: 70,
      },
      {
        x: 'moto',
        y: 98,
      },
      {
        x: 'bicycle',
        y: 225,
      },
      {
        x: 'horse',
        y: 254,
      },
      {
        x: 'skateboard',
        y: 172,
      },
      {
        x: 'others',
        y: 9,
      },
    ],
  },
  {
    id: 'norway',
    color: 'hsl(83, 70%, 50%)',
    data: [
      {
        x: 'plane',
        y: 166,
      },
      {
        x: 'helicopter',
        y: 58,
      },
      {
        x: 'boat',
        y: 14,
      },
      {
        x: 'train',
        y: 219,
      },
      {
        x: 'subway',
        y: 41,
      },
      {
        x: 'bus',
        y: 46,
      },
      {
        x: 'car',
        y: 233,
      },
      {
        x: 'moto',
        y: 44,
      },
      {
        x: 'bicycle',
        y: 186,
      },
      {
        x: 'horse',
        y: 222,
      },
      {
        x: 'skateboard',
        y: 29,
      },
      {
        x: 'others',
        y: 38,
      },
    ],
  },
];
