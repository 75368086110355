import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { TbChefHat } from 'react-icons/tb';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { CgSmartHomeWashMachine } from 'react-icons/cg';
import { gjgsCommercial, gjgsSystemAircon, kitcheniol } from 'types/admin/route';
import { setShowSidebar } from 'reducers/layout';
import { isMobile } from 'react-device-detect';

export default function SideBar() {
  const dispatch = useAppDispatch();
  const showSidebar = useAppSelector(({ layoutReducer }) => layoutReducer.showSidebar);

  return (
    <>
      <aside
        id="logo-sidebar"
        aria-label="Sidebar"
        className={clsx(
          'fixed left-0 top-0 z-40 h-screen w-64 -translate-x-full border-r border-gray-200 bg-white pt-20 transition-transform dark:border-gray-700 dark:bg-gray-800 lg:translate-x-0',
          {
            ['transform-none']: showSidebar,
          },
        )}
        onClick={() => dispatch(setShowSidebar(!showSidebar))}
      >
        <div className="h-full overflow-y-auto bg-white px-3 pb-4 dark:bg-gray-800">
          <ul className="space-y-2 font-medium">
            <li>
              <Link
                to={`/admin/${gjgsCommercial}/dashboard`}
                className="flex items-center rounded-lg p-2 text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
              >
                <CgSmartHomeWashMachine className="h-6 w-6 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white" />
                <span className="ml-3">가전각색 커머셜</span>
              </Link>
            </li>

            <li>
              <Link
                to={`/admin/${gjgsSystemAircon}/dashboard`}
                className="flex items-center rounded-lg p-2 text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
              >
                <CgSmartHomeWashMachine className="h-6 w-6 shrink-0 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white" />
                <span className="ml-3 flex-1 whitespace-nowrap">시스템에어컨</span>
                <span className="ml-3 inline-flex items-center justify-center rounded-full bg-blue-200 px-2 text-sm font-medium text-gray-800 dark:bg-gray-700 dark:text-gray-300">
                  coming
                </span>
              </Link>
            </li>
            <li>
              <Link
                to={`/admin/${kitcheniol}/dashboard`}
                className="flex items-center rounded-lg p-2 text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
              >
                <TbChefHat className="h-6 w-6 shrink-0 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white" />
                <span className="ml-3 flex-1 whitespace-nowrap">키치니올</span>
                <span className="ml-3 inline-flex items-center justify-center rounded-full bg-blue-200 px-2 text-sm font-medium text-gray-800 dark:bg-gray-700 dark:text-gray-300">
                  coming
                </span>
              </Link>
            </li>
          </ul>
        </div>
      </aside>
      <div
        className={clsx({ ['fixed inset-0 z-30 bg-gray-900/50']: showSidebar && isMobile })}
        onClick={() => dispatch(setShowSidebar(!showSidebar))}
      ></div>
    </>
  );
}
