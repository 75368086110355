export const CLIENT_ID = '145600467697-4np87ldtgdf18di36i7li19tvpmcp6al.apps.googleusercontent.com';
const AUTHORIZE_URI = 'https://accounts.google.com/o/oauth2/v2/auth';
export const API_KEY = 'AIzaSyAzGAEhq5Jj4tL865ltlPBN9Yks-FOBr70';

// const queryStr = qs.stringify({
//   client_id: CLIENT_ID,
//   redirect_uri: window.location.href,
//   response_type: 'token',
//   scope: 'https://www.googleapis.com/auth/contacts.readonly',
// });

// const loginUrl = AUTHORIZE_URI + '?' + queryStr;

// <div id="g_id_onload"
//      data-client_id="145600467697-4np87ldtgdf18di36i7li19tvpmcp6al.apps.googleusercontent.com"
//      data-context="signin"
//      data-ux_mode="popup"
//      data-login_uri="http://localhost:3000/admin"
//      data-auto_prompt="false">
// </div>

// <div class="g_id_signin"
//      data-type="standard"
//      data-shape="rectangular"
//      data-theme="filled_blue"
//      data-text="signin_with"
//      data-size="large"
//      data-logo_alignment="left">
// </div>
