import { createSlice } from '@reduxjs/toolkit';
import { LayoutState } from 'types';

export const initialState: LayoutState = {
  showSidebar: false,
};

export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    setShowSidebar: (state, action) => {
      state.showSidebar = action.payload;
    },
  },
});

export const { setShowSidebar } = layoutSlice.actions;
export default layoutSlice.reducer;
