import clsx from 'clsx';
import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
import Header from 'components/layout/admin/Header';
import Content from 'components/layout/admin/Content';
import SideBar from 'components/layout/admin/Sidebar';
import AirconDashboard from 'components/admin/systemAircon/AirconDashboard';
import CommercialDashboard from 'components/admin/commercial/CommercialDashboard';
import KitcheniolDashboard from 'components/admin/kitcheniol/KitcheniolDashboard';
import {
  commercial,
  kitcheniol,
  systemAircon,
  AdminRouteTypes,
  gjgsCommercial,
  gjgsSystemAircon,
  RouteBusinessTypes,
} from 'types/admin/route';

export default function AdminTemplate() {
  const { menuType } = useParams<{ menuType: AdminRouteTypes }>();
  const [type, setType] = useState<RouteBusinessTypes>([gjgsCommercial, commercial]);

  // const { status, isLoading, isSuccess, isError, data, error } = useQuery(
  //   monthlySalesKeys.businessType(type[1]),
  //   () => getMonthlySales(),
  // );

  // const queryClient = useQueryClient({});
  // console.log(queryClient.getQueryData(monthlySalesKeys.businessType(type[1])));

  useEffect(() => {
    if (menuType === gjgsCommercial) {
      setType([gjgsCommercial, commercial]);
    }
    if (menuType === gjgsSystemAircon) {
      setType([gjgsSystemAircon, systemAircon]);
    }
    if (menuType === kitcheniol) {
      setType([kitcheniol, kitcheniol]);
    }
  }, [menuType]);

  return (
    <>
      <Header />
      <SideBar />
      <section
        className={clsx('main_container', {
          ['flex-col']: true,
        })}
      >
        {menuType === gjgsCommercial && <CommercialDashboard type={type} />}
        {menuType === gjgsSystemAircon && <AirconDashboard />}
        {menuType === kitcheniol && <KitcheniolDashboard />}
      </section>
    </>
  );
}
