export const LANGUAGE_FOR_OS = {
  EN: 'en',
  KO: 'ko',
  EN_US: 'en-US',
  KO_KR: 'ko-KR',
};

export const HOME_IMG_SRC = {
  KOR_LOGO: '/images/iol_logo-kor.svg',
  ENG_LOGO: '/images/iol_logo-eng.svg',
  KOR_CHART: '/images/home/img_chart_kor.svg',
  ENG_CHART: '/images/home/img_chart_eng.svg',
  KOR_LOGO_BASE: '/images/aboutUs/img_logo-base.svg',
  ENG_LOGO_BASE: '/images/aboutUs/img_logo-base_eng.svg',
};
export const ROUTES = {
  HOME: '/',
  ABOUT: 'about-us',
  CONTACT: 'contact-us',
};
export const WELFARE_NUM = 8;
export const LANG_ICON = 'LANGUAGE_SETTING_ICON';

export const TEAM_MEMBER = [
  {
    fileName: 'yk',
    role: 'CEO',
  },
  {
    fileName: 'kr',
    role: 'Manager',
  },
  {
    fileName: 'sj',
    role: 'Manager',
  },
  {
    fileName: 'psh',
    role: 'Manager',
  },
  ,
  {
    fileName: 'jk',
    role: 'Software Engineer, Backend',
  },
  {
    fileName: 'ms',
    role: 'Software Engineer, Frontend',
  },
  {
    fileName: 'ksh',
    role: 'Producer',
  },
  {
    fileName: 'jh',
    role: 'Designer',
  },
  ,
];

export const INQUIRY_LITERALS = {
  name: 'name',
  phoneNumber: 'phoneNumber',
  email: 'email',
  type: 'type',
  content: 'content',
};

export const CONTACT_ITEMS = [
  { title: INQUIRY_LITERALS.name, ariaLabel: '이름' },
  { title: INQUIRY_LITERALS.phoneNumber, ariaLabel: '연락처' },
  { title: INQUIRY_LITERALS.email, ariaLabel: '이메일' },
];
