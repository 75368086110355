import { HOME_IMG_SRC, LANGUAGE_FOR_OS, TEAM_MEMBER, WELFARE_NUM } from 'constants/common';
import { useState } from 'react';
import { useEffect } from 'react';
import { isDesktop, isTablet } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

function AboutUs() {
  const { t, i18n } = useTranslation();
  const [logoBaseSrc, setLogoBaseSrc] = useState(() => {
    if (i18n.language.includes(LANGUAGE_FOR_OS.KO)) {
      return HOME_IMG_SRC.KOR_LOGO_BASE;
    }
    if (i18n.language.includes(LANGUAGE_FOR_OS.EN)) {
      return HOME_IMG_SRC.ENG_LOGO_BASE;
    }
  });
  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  useEffect(() => {
    const { language } = i18n;
    if (language.includes(LANGUAGE_FOR_OS.EN)) {
      setLogoBaseSrc(HOME_IMG_SRC.ENG_LOGO_BASE);
    }
    if (language.includes(LANGUAGE_FOR_OS.KO)) {
      setLogoBaseSrc(HOME_IMG_SRC.KOR_LOGO_BASE);
    }
  }, [i18n.language]);

  return (
    <>
      <section className="container flex h-full w-full items-center  lg:min-h-[1000px] xl:h-[calc(100vh-128px)]">
        <div className="flex w-full flex-col items-center">
          <h1 className="mt-12 text-xl font-bold md:text-4xl">{t('aboutTitle')}</h1>
          <div>
            <p className="mb-3 mt-12 min-w-[266px] max-w-[304px] text-center font-normal  md:max-w-full md:text-lg lg:w-full lg:text-xl">
              {t('aboutContent1')}
            </p>

            <p className="mb-4  min-w-[266px] max-w-[304px] text-center text-base font-normal md:max-w-full md:text-lg lg:w-full lg:text-xl ">
              {t('aboutContent2')}
            </p>
          </div>
          <img src={logoBaseSrc} alt="iol logo" className="my-6 md:my-24" />
          <article className="mb-4 flex w-full flex-col items-center justify-evenly lg:min-w-[1000px] lg:flex-row">
            <div className="my-2 flex h-48 max-w-[30rem] items-center rounded-4xl bg-gray-50 p-3 lg:w-[36rem]">
              <img src="/images/aboutUs/iol_person.png" className="w-36" alt="iol person" />

              <p className="pr-4 text-sm leading-7 text-gray-500 md:text-base md:leading-10">
                {t('logoExplanation1')}
                <span className="text-green">{t('logoExplanationAccent')}</span>
                {t('logoExplanation2')}
              </p>
            </div>
            <div className="my-2 flex h-48 max-w-[30rem]  items-center  rounded-4xl bg-gray-50 p-3 lg:w-[36rem] ">
              <img src="/images/aboutUs/iol_handwriting.png" className="w-36" alt="iol person" />
              <p className="pr-4 text-sm leading-7 text-gray-500 md:text-base md:leading-10">
                {t('nameExplanation1')} <span className="text-green">i</span>mprove{' '}
                <span className="text-green">o</span>ur <span className="text-green">l</span>ives
                {t('nameExplanation2')}
                {t('nameExplanation3')}
              </p>
            </div>
            {/* <div className="my-2 flex  max-w-[36rem] items-center rounded-4xl bg-gray-50 p-3 lg:w-[36rem] ">
              <img
                src="/images/aboutUs/iol_handwriting.png"
                className="w-36 min-w-[100px]"
                alt="iol handwriting"
              />

              <p className="pr-4 text-sm leading-7 text-gray-500 md:text-base md:leading-10">
                {t('nameExplanation1')} <span className="text-green">i</span>mprove{' '}
                <span className="text-green">o</span>ur <span className="text-green">l</span>ives
                {t('nameExplanation2')}
                <br />
                {t('nameExplanation3')}
              </p>
            </div> */}
          </article>
        </div>
      </section>
      <section className="relative bg-gray-50 py-32 ">
        <img
          src="/images/aboutUs/bg_obj1.svg"
          alt="obj"
          className="absolute top-8 -left-40 md:left-[initial] md:right-28 md:top-24"
        />
        <img
          src="/images/aboutUs/bg_obj2.svg"
          alt="obj"
          className="absolute bottom-14 md:top-1/2 md:bottom-[initial]"
        />
        <img
          src="/images/aboutUs/bg_obj3.svg"
          alt="obj"
          className="absolute right-4 top-96 md:bottom-40 md:right-8 md:top-[initial] lg:right-56"
        />

        <div className="container grid w-full grid-rows-4 justify-center gap-7 md:h-[calc(100vh-128px)] md:grid-cols-[repeat(2,minmax(300px,_31rem))] lg:min-h-[1000px]">
          <article className="z-10">
            <div className="ml-[-1rem] h-[0.6rem] w-[0.6rem] rounded-full border-4 border-secondary bg-secondary"></div>
            <h2 className="mb-8 text-xl font-bold md:text-2xl lg:text-3xl">{t('aboutCultureTitle1')}</h2>
            <p className="leading-8 md:text-[18px] md:leading-[40px] lg:leading-[50px]">
              {t('aboutCultureContent1')}
            </p>
          </article>
          {(isDesktop || isTablet) && <article></article>}

          {(isDesktop || isTablet) && <article></article>}
          <article className="z-10">
            <div className="ml-[-1rem] h-[0.6rem] w-[0.6rem] rounded-full border-4 border-secondary bg-secondary"></div>
            <h2 className="mb-8 text-xl font-bold md:text-2xl lg:text-3xl">{t('aboutCultureTitle2')}</h2>
            <p className="leading-8 md:text-[18px] md:leading-[40px] lg:leading-[50px]">
              {t('aboutCultureContent2')}
            </p>
          </article>

          <article className="z-10">
            <div className="ml-[-1rem] h-[0.6rem] w-[0.6rem] rounded-full border-4 border-secondary bg-secondary"></div>
            <h2 className="mb-8 text-xl font-bold md:text-2xl lg:text-3xl">{t('aboutCultureTitle3')}</h2>
            <p className="leading-8 md:text-[18px] md:leading-[40px] lg:leading-[50px]">
              {t('aboutCultureContent3')}
            </p>
          </article>
          {(isDesktop || isTablet) && <article></article>}

          {(isDesktop || isTablet) && <article></article>}
          <article className="z-10">
            <div className="ml-[-1rem] h-[0.6rem] w-[0.6rem] rounded-full border-4 border-secondary bg-secondary"></div>
            <h2 className="mb-8 text-xl font-bold md:text-2xl lg:text-3xl">{t('aboutCultureTitle4')}</h2>
            <p className="leading-8 md:text-[18px] md:leading-[40px] lg:leading-[50px]">
              {t('aboutCultureContent4')}
            </p>
          </article>
        </div>
      </section>

      <section className="container my-12 flex w-full items-center lg:h-[calc(100vh-128px)] lg:min-h-[1000px]">
        <div className="flex w-full flex-col items-center">
          <h1 className="mt-12 mb-8 text-xl font-bold md:mb-16 md:text-4xl">{t('aboutWelfareTitle')}</h1>

          <div className="grid grid-cols-2 gap-4 md:grid-cols-3  md:gap-11 lg:grid-cols-4 lg:gap-9">
            {[...new Array(WELFARE_NUM)].map((_, idx) => (
              <div key={idx} className="flex flex-col items-center ">
                <img src={`/images/aboutUs/welfare_${idx + 1}.svg`} alt="복지" />

                <h2 className="mb-3 text-center text-base font-bold md:text-2xl">
                  {t(`aboutWelfare${idx + 1}Title`)}
                </h2>
                <p className=" text-center text-sm md:w-52 md:text-base lg:w-60">
                  {t(`aboutWelfare${idx + 1}Content`)}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* <section className="container my-12 flex w-full items-center lg:h-[calc(100vh-128px)] lg:min-h-[1000px]">
        <div className="flex w-full flex-col items-center">
          <h1 className="mt-12 mb-8 text-xl font-bold md:mb-16 md:text-4xl">{t('aboutTeam')}</h1>

          <div className="grid grid-cols-2 gap-4 md:grid-cols-3 md:gap-11  lg:grid-cols-4 lg:gap-11">
            {TEAM_MEMBER.map((elem, idx) => (
              <div key={idx} className="flex flex-col items-center ">
                <img
                  src={`/images/aboutUs/${elem?.fileName}.png`}
                  alt={`${elem?.fileName}`}
                  className="w-[240px] min-w-[150px]"
                />

                <p className="text-center text-sm md:w-52 md:text-base">{elem?.role}</p>
                <h2 className="mb-3 text-base font-bold md:text-2xl">{t(`${elem?.fileName}`)}</h2>
              </div>
            ))}
          </div>
        </div>
      </section> */}
    </>
  );
}

export default AboutUs;
